import React, {Component} from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Row from '../row'
import SelectIcon from "../../../assets/selecticon.svg"

const FormContainer = styled.div`
    box-sizing: border-box;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;

    }

    @media only screen and (min-width:62em) {
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    p {
        font-size: 12px;
        color: rgb(97, 98, 109);
    }
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  padding-bottom: 12px;

  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex: 1;
  }
`;
const FormInput = styled.input`
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E8E8E8;
  color: rgb(45, 47, 61);
  display: block;
  font-size: 16px;
  padding: 12px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: rgb(45, 47, 61);
  }
`;
const FormButton = styled.button`
  margin: 12px auto 0px;
  width: 100%;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #FFFFFF;
  position: relative;
  margin-bottom: 10px;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
  }

`;

const FormSelectDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const FormSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 12px;
  padding-right: 36px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.3;
  color: rgb(45, 47, 61);
  background-color: #FFFFFF;
  height: 50px;

  ::-webkit-input-placeholder {
    color: #61626D;
  }

  ::-ms-expand {
    display: none;
  }
`;
const FormSelectIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 0;
  width: 40px;

  svg {
    font-size: 24px;
  }
`;


class NewsletterRegistration extends Component {

  state = {
    SALUTATION: null,
    LNAME: null,
    email: null,
    USERLANG: this.props.currentlanguage,
    SOURCEPAGE: this.props.sourcepagede,
  }

  _handleChange = e => {
    this.setState({
        [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    console.log('submit', this.state)

    addToMailchimp(this.state.email, this.state)
        .then(({ msg, result }) => {
            console.log('msg', `${result}: ${msg}`)

            if (result !== 'success') {
                throw msg
            }
            alert(msg)
        })
        .catch(err => {
            console.log('err', err)
            alert(err)
        })
  }
    render () {
      return (
        <FormContainer>
            {this.props.currentlanguage === "de" &&
            <React.Fragment>
            <form onSubmit={this._handleSubmit}>
                <input type="hidden" name="newsletter-de" value="newsletter-de"/>
                <input type="hidden" name="source-page" value={this.props.sourcepagede}/>
                <input type="hidden" name="userlanguage" value={this.props.currentlanguage}/>
                <Row>
                    <Flexcolumn>
                    <FormSelectDiv>              
                        <FormSelect type="anrede" name="SALUTATION" id="anrede-newsletter" aria-label="Anrede Newsletter" placeholder="Anrede*" onChange={this._handleChange} required>
                            <option selected="" value="">Bitte wählen</option>
                            <option value="Frau">Frau</option>
                            <option value="Herr">Herr</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                        </FormSelectDiv>
                    </Flexcolumn>
                </Row>
                <Row>
                    <Flexcolumn>              
                        <FormInput type="text" name="LNAME" id="nachname-newsletter" aria-label="Nachname" placeholder="Nachname" onChange={this._handleChange} required />
                    </Flexcolumn>
                </Row>
                <Row>
                    <Flexcolumn>              
                        <FormInput type="email" name="email" id="email-newsletter" aria-label="E-Mail" placeholder="E-Mail" onChange={this._handleChange} required />
                    </Flexcolumn>
                </Row>
                <Row>
                    <FormButton type="submit" required>
                        Jetzt anmelden
                    </FormButton>
                </Row>
                <Row>
                    <p>Hinweise zum Widerruf und der Verarbeitung von Daten geben wir in unserer Datenschutzerklärung.</p>
                </Row>
            </form>
            </React.Fragment>
            }
            {this.props.currentlanguage === "en" &&
            <React.Fragment>
            <form name="newsletter-en" method="POST" action="/en/contact/your-inquiry/">
                <input type="hidden" name="newsletter-en" value="newsletter-en"/>
                <input type="hidden" name="source-page" value={this.props.sourcepageen}/>
                <input type="hidden" name="userlanguage" value={this.props.currentlanguage}/>
                <Row>
                    <Flexcolumn>
                      <FormSelectDiv>                   
                        <FormSelect type="anrede" name="newsletter-salutation" id="newsletter-salutation" aria-label="Newsletter-Salutation" placeholder="Prefix*" required>
                            <option selected="" value="">Please Select</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Ms.">Ms.</option>
                          </FormSelect>
                          <FormSelectIcon>
                            <span>
                              <SelectIcon />
                            </span>
                          </FormSelectIcon>
                      </FormSelectDiv>     
                    </Flexcolumn>
                </Row>
                <Row>
                    <Flexcolumn>              
                        <FormInput type="last-name" name="last-name-newsletter" id="last-name-newsletter" aria-label="last-name" placeholder="Last Name" required />
                    </Flexcolumn>
                </Row>
                <Row>
                    <Flexcolumn>              
                        <FormInput type="email" name="email-newsletter" id="email-newsletter" aria-label="Newsletter-E-Mail" placeholder="E-Mail" required />
                    </Flexcolumn>
                </Row>
                <Row>
                    <FormButton type="submit" required>
                        Subscribe Now
                    </FormButton>
                </Row>
                <Row>
                    <p>Further information about our processing of data and your right of revocation can be found in our Data Protection Declaration.</p>
                </Row>
            </form>
            </React.Fragment>
            }
        </FormContainer>

      )
    }
  }
  
  export default NewsletterRegistration;
  

