import React from 'react'
import Footer from '../components/footer/footer'
import SubFooter from '../components/footer/subfooter'
import { GlobalStyle } from '../theme/globalstyle';

const TemplateWrapper = ({ children, langlinkde, langlinken, currentlang }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      {children}

      {currentlang === "de" &&
        <Footer langlinkde={langlinkde} 
                langlinken={langlinken}
                currentlangfooter={currentlang}
        />
      }
      {currentlang === "en" &&
        <Footer langlinkde={langlinkde} 
                langlinken={langlinken}
                currentlangfooter={currentlang}
        />
      }
      <SubFooter currentlangfooter={currentlang}/>
    </React.Fragment>
  )
}

export default TemplateWrapper
